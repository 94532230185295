<template>
  <div class="page">
    <div v-if="loading" class="head"></div>
    <div v-else class="head">
      <div class="title">
        <p>
          <span @click="pull_up" data-value="detail"  >{{xing_info.word}}</span>
          <span class="b"  @click="pull_up" data-value="detail"> 详情</span>
        </p>
      </div>
      <div class="about">
        <div class="l1">
          <p  class="black_tag" @click="pull_up" data-value="chengyu" > 谐音成语 </p>
          <p  class="black_tag" @click="pull_up" data-value="ciyu" > {{xing_info.word}}姓词语 </p>
          <p  class="black_tag" @click="pull_up" data-value="people" > 名人明星 </p>
        </div>
      </div>
    </div>

    <van-tabs v-model:active="which_page"  animated swipeable sticky isFixed  swipe-threshold="3"  color="orange" @change="click_tabs"  >
      <van-tab title="名字推荐" title-style="color:orange;"  >
        <p v-if="num_info.jing>0" class='green_tips'>
          <van-icon name="down" size='0.45rem' color='#4fc08d'/>
          <span>精选名字：{{num_info.jing}}个</span>
        </p>
        <div  v-if="num_info.jing>0" id='jingxuan' class="mings_div">
          <div class="names">
            <router-link :class="'one_name_jing background_'+ item.color"   v-for="(item, index) in name_list_jing"  :key="index"  :to="{name : 'name_detail', query : {name : item.word}}">{{item.word}}</router-link>
            <span v-if="num_info.jing>3" class="more" data-value=""  @click="go_to_name_jing">更 多</span>
          </div>
        </div>
        <p v-if="num_info.ming>0" class='green_tips'>
          <van-icon name="down" size='0.45rem' color='#4fc08d'/>
          <span> {{xing_info.word + ' '}} </span>
          <van-icon name="like" color="#BEDAE7"  size="0.45rem"  />
          <van-icon name="like" color="#BEDAE7"  size="0.45rem"  />
          <span>{{num_info.ming}}个</span>
        </p>
        <div class="mings_div">
          <router-link :class="'one_ming background_'+ item.color"  v-for="(item, index) in list.ming" :key="index" :to="{name : 'name_detail', query : {name : item.word}}" >{{item.word_ming}}</router-link>
        </div>
        <van-pagination v-model="page.ming" :page-count="Math.ceil(num_info.ming/50 )" mode="simple" @change="fanye_ming" />
        <p v-if="can_see_more" class="warn_tips"  data-value="bind_tips" @click="xml_go_to">使用微信小程序，体验更佳。查看如何绑定</p>
      </van-tab>
      <van-tab title="诗词起名" title-style="color:orange;">
        <van-skeleton v-if="loading2" :row="6" />
        <div v-else>
          <button  v-if="!can_see_more" class="to_be_vip" data-value="name_xing|shiju" @click="xml_go_to_goods_index">会员可借助100万句古诗词起名</button>
          <div class="shiju_info" data-value="search_poe" bindtap="pull_up">
            <span class="gray">发现{{num_info['shiju']}}条 包含</span>
            <div  v-if="search_info_shiju.key1_list.length >3" class="key_list">
              <span class="xing" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >{{search_info_shiju.key1_list[0]}}</span>
              <span class="xing" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >{{search_info_shiju.key1_list[1]}}</span>
              <span class="xing" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >{{search_info_shiju.key1_list[2]}}</span>
              <span class="xing" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >...</span>
            </div>
            <div v-else class="key_list" >
              <span v-for="(xing, index) in search_info_shiju.key1_list" :key="index" class="xing" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >{{xing}}</span>
            </div>
            <span class="gray">的诗词</span>
          </div>
          <div v-for="(shiju, index) in list.shiju" :key="index" class="shiju" @click="get_poe_detail" :data-value="shiju.id">
            <div class="left" v-html="shiju.warn_p" ></div>
            <div class="right"><van-icon name="ellipsis" color="#ccc" size="0.5rem" /></div>
          </div>
          <van-pagination v-model="page.shiju" :page-count="Math.ceil(num_info.shiju/20 )" mode="simple" @change="fanye_shiju" />
          <p v-if="can_see_more" class="warn_tips"  data-value="bind_tips" @click="xml_go_to">使用微信小程序，体验更佳。查看如何绑定</p>
        </div>
      </van-tab>
      <van-tab title="配字起名" title-style="color:orange;">
        <WuXing :xing="xing_info.word" from_page='name_xing'></WuXing>
      </van-tab>
      <van-tab title="配字起名" title-style="color:orange;">
        <div class="mpd">
          <input v-model="fit_str" type="text" placeholder="请输入一个汉字"  />
          <button class="in_body black"   @click="fit_name"  >确定</button>
        </div>
        <p class='green_tips'>
          <van-icon name="down" size='32rpx' color='#4fc08d'/>
          <span>例</span>
        </p>
        <ul>
          <li  v-for="(guide, index) in list.guide" :key="index"  class="guide" :data-value="index" @click="guide_to_fit">
            <div class="left">{{guide.word}}</div>
            <div class="middle">
              <div class="way">
                <p class='cx'>+ {{guide.desc}}</p>
                <p v-if=" guide.use_way.fit_place === '在前'">
                  <span class='b'>{{ xing_info.word }}</span>
                  <span class='b guide_warn'>{{ guide.word}}</span>
                  <span class='b'>◇</span>
                </p>
                <p v-else>
                  <span class='b'>{{ xing_info.word }}</span>
                  <span class='b'>◇</span>
                  <span class='b guide_warn'>{{ guide.word}}</span>
                </p>
              </div>
              <div class="ex">
                <span>例:</span>
                <span v-for="ex in guide.ex" :key="ex" >{{ex}}</span>
              </div>
            </div>
            <div class="right">
              <van-icon name="arrow" color="#ccc" size="0.45rem" />
            </div>
          </li>
        </ul>
      </van-tab>
      <van-tab title="父母姓结合" title-style="color:orange;">
        <div class="mpd">
          <input v-model="xing2" type="text" placeholder="请输入配偶姓氏"  />
          <button class="in_body black"   @click="fumu_name"  >确 定</button>
        </div>
      </van-tab>
      <van-tab title="名字参考" title-style="color:orange;">
        <button  v-if="!can_see_more" class="to_be_vip"  data-value="name_xing|tag" @click="xml_go_to_goods_index">会员可查看所有标签下的名字</button>
        <p v-if="!loading2" class="tips">本页意在提供起名思路参考~</p>
        <van-skeleton v-if="loading2" :row="6" />
        <ul id="tags">
          <li  v-for="(tag, index) in tag_list"   :key="index" >
            <p class="one_tag" :data-value="index" @click="to_select">{{tag.tag}} · {{tag.select?tag.nums:'▼'}}</p>
            <div v-if="tag.select" class="names">
              <router-link v-for="(item, j) in tag.better_list"  :class="'background_' + item.color" :key="j" :to="{name : 'name_detail', query : {name : item.word}}">{{item.word}}</router-link>
              <p class="more" :data-value="tag.tag" @click="get_more">更 多</p>
            </div>
          </li>
        </ul>
      </van-tab>
    </van-tabs>
    <!-- <div class="foot" v-if="which_page===1 && !loading2">
      <button class="black w1" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >搜古诗词</button>
    </div>
    <div class="foot" v-if="which_page===0 && !loading2">
      <button class="black w1" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >筛选排序</button>
    </div> -->
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>

    <van-popup v-model:show="show.poe"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
      <van-skeleton v-if="loading2" :row="6" />
      <div v-else>
        <div class="head">
          <div class="title">
            <p class="title2">{{current_poe.title}}</p>
          </div>
          <div class="about">
            <div class="l1">
              <p>{{current_poe.dynasty}} -- {{current_poe.author}}</p>
            </div>
          </div>
        </div>

        <div  v-for="(juzi,index) in current_poe.body" :key="index" class="shiju2">
          {{juzi}}
        </div>
        <SearchName></SearchName>
      </div>
    </van-popup>
    <van-popup v-model:show="show.detail"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
      <DetailXing :info="xing_info" />
    </van-popup>
    <van-popup v-model:show="show.ciyu"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListCiyu :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
    </van-popup>
    <van-popup v-model:show="show.chengyu"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListChengyu :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
    </van-popup>
    <van-popup v-model:show="show.people"  round  position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListPeople :word="xing_info.word" tag="xing" from_page="name_xing" :can_see_more="can_see_more"/>
    </van-popup>
  </div>
</template>

<script>

import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'

import SearchName from '@/components/other/SearchName'
import ListCiyu from '@/components/other/ListCiyu'
import ListChengyu from '@/components/other/ListChengyu'
import ListPeople from '@/components/other/ListPeople'
import DetailXing from '@/components/other/DetailXing'
import WuXing from '@/components/other/WuXing'

export default {
  name: 'name_xing',
  store,
  components: {
    SearchName, ListCiyu, ListChengyu, ListPeople, DetailXing, WuXing
  },
  data () {
    return {
      lloading: true,
      loading2: false,
      base_img: store.state.base_img,
      show: { detail: false, poe: false, ciyu: false, chengyu: false, people: false },
      which_page: 0,
      can_see_more: false,
      xing_info: { zi_info: { mean: {} } },
      list: { shiju: [], guide: [], ming: [] },
      page: { shiju: 1, guide: 1, ming: 1 },
      tag_list: [],
      name_list_jing: [],
      current_poe: {},
      xing2: '',
      search_info_shiju: {
        key1_word: '',
        key1_more: true,
        key1_list: [],
        key2_word: '',
        key2_more: false,
        key2_list: []
      },
      search_info_ming: {
        paixu: '默认排序',
        words: '',
        more: '否'
      },
      num_info: {
        shiju: 0,
        ming: 0,
        jing: 0
      },
      fit_str: '',
      name_str: ''
    }
  },
  // shengyin: { duoyin: {'双多音': {}, '多音字': {} }, base: {韵律: {}, 同音: {}, 连读: {}, 连生母: {}, 连韵母: {} } },
  mounted () {
    this.xing_index()
  },

  methods: {
    xing_index () {
      axios.post('/xing_index/', { word: this.$route.query.word })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }

          this.xing_info = res.data.data.xing_info
          this.can_see_more = res.data.data.can_see_more
          this.num_info = res.data.data.num_info
          this.name_list_jing = res.data.data.name_list_jing
          this.loading = false
          this.search_info_shiju = res.data.data.search_info_shiju
          this.get_ming_list(1)
          Utils.alert_tip_list('name_xing', res.data.data.tip_list)
        })
        .catch(err => {
          alert(err)
        })
    },
    click_tabs (e) {
      this.which_page = e
      if (e === 1 && !this.list.shiju.length) {
        // Utils.is_need_alert_tips('ming', '点击名字则和姓氏组成全名')
        this.get_shiju_list(1)
      }
      if (e === 3 && !this.list.guide.length) {
        Utils.is_need_alert_tips('guide', '您也可以添加一个关键字 来组合名字，如辈分字')
        this.get_guide_list()
      }
      if (e === 4) {
        Utils.is_need_alert_tips('fumu', '将以【慕、知、念、思、惜、和、见、遇及其谐音】组合出名字，以做参考')
      }
      if (e === 5 && !this.tag_list.length) {
        Utils.is_need_alert_tips('tag_name', '小孩的名字通常寄托了父母的期望，系统收录了数十个期望类型，以及对应的的名字，为您提供引导和借鉴。')
        this.xing_tag_list()
      }
    },
    to_app_store: function (e) {
      Utils.go_to_app(e.currentTarget.dataset.value, '')
    },
    fanye_shiju (e) {
      this.get_shiju_list(e)
    },
    fanye_ming (e) {
      this.get_ming_list(e)
    },
    get_shiju_list (e) {
      this.loading2 = true
      const data = { search_info: this.search_info_shiju, page: e }
      axios.post('/shiju_list/', data)
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (res.data.data.need_vip) {
            Utils.show_ask_goods({ page: 'name_xing', tag: 'shiju' })
            return
          }
          this.list.shiju = res.data.data.list
          window.scrollTo(0, 0)
        })
    },
    get_ming_list (e) {
      this.loading2 = true
      const data = { xing: this.xing_info.word, search_info: this.search_info_ming, page: e }
      axios.post('/basic_ming_list/', data)
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (res.data.data.need_vip) {
            Utils.show_ask_goods({ page: 'name_xing', tag: 'basic_ming' })
            return
          }
          this.list.ming = res.data.data.list
          window.scrollTo(0, 0)
        })
    },
    get_guide_list (e) {
      this.loading2 = true
      const data = { word: this.xing_info.word, page: this.page.guide }
      axios.post('/guide_list/', data)
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.list.guide = res.data.data.list
          window.scrollTo(0, 0)
        })
    },
    fumu_name (e) {
      if (this.xing2.length !== 1) {
        Utils.alert({ content: '请输入单个字' })
      } else {
        router.push({ name: 'name_fumu', query: { xing1: this.xing_info.word, xing2: this.xing2 } })
      }
    },
    xing_tag_list (e) {
      axios.post('/xing_tag_list/', { word: this.xing_info.word })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.tag_list = res.data.data.tag_list
        })
    },
    to_select (e) {
      const tt = e.currentTarget.dataset.value
      this.tag_list[tt].select = this.tag_list[tt].select === false
    },
    guide_to_fit (e) {
      const tt = e.currentTarget.dataset.value
      router.push({ name: 'name_fit', query: { xing: this.xing_info.word, word: this.list.guide[tt].word, use_way: JSON.stringify(this.list.guide[tt].use_way) } })
    },
    get_more (e) {
      if (!this.can_see_more) {
        Utils.show_ask_goods({ page: 'name_xing', tag: 'tag' })
        return
      }
      const tt = e.currentTarget.dataset.value
      router.push({ name: 'name_list', query: { value: tt, type: 'tag', xing: this.xing_info.word, page: 1 } })
    },
    go_to_name_jing: function (e) {
      if (!this.can_see_more) {
        Utils.show_ask_goods({ page: 'name_xing', tag: 'jing' })
        return
      }
      router.push({ name: 'name_list', query: { value: '', type: 'jing', xing: this.xing_info.word, page: 1 } })
    },
    want_alert (e) {
      Utils.alert({ content: e.currentTarget.dataset.value })
    },
    fit_name (e) {
      if (this.fit_str.length !== 1) {
        Utils.alert({ content: '请输入单个字' })
        return
      }
      router.push({ name: 'name_fit', query: { xing: this.xing_info.word, word: this.fit_str } })
    },
    get_poe_detail (e) {
      this.loading2 = true
      this.show.poe = true
      this.current_poe = {}
      axios.post('/poe_detail/', { shiju_id: e.currentTarget.dataset.value })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.current_poe = res.data.data.info
        })
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close (e) {
      this.show = { detail: false, poe: false, ciyu: false, chengyu: false, people: false }
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>
.page{
  padding-bottom: 3.6rem;
}
#tags{
  padding: 0rem 0.6rem;
  text-align: left;
  float: left;
  background-color: #f8f8f8;
}
#tags li{
  display: inline-block;
}
#tags .one_tag{
  display: inline-block;
  padding: 0rem 0.4rem;
  height: 1rem;
  line-height: 1rem;
  background-color: rgba(137, 190, 36, 1);
  color: #fefefe;
  margin: 0.2rem 0.18rem 0.3rem 0rem;
  border-radius: 0.5rem;
  font-size: 0.36rem;
}
#tags .names{
  margin: 0.1rem 0rem 0.3rem 0.3rem;
  display: block;
}
#tags .names p{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
}
#tags .names a{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
  color: #fefefe;
  margin-right: 0.30rem;
  line-height: 0.9rem;
  width: 1.8rem;
}

#tags .more{
  color: #81D3F8;
  background-color:rgba(129, 211, 248, 0.1);
  width: 1.8rem;
  height: 0.9rem;
  line-height: 0.9rem;
}

.guide{
  margin: 0.40rem 0rem ;
  padding: 0.40rem 0.6rem;
  background:#fefefe;
  height: 1.6rem;
  text-align: left;
  position: relative;
  vertical-align: top;

}
.guide div{
  display: inline-block;
}
.guide .left{
  vertical-align: top;
  text-align: center;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1rem;
  color: #fefefe;
  line-height: 1.6rem;
  background-color: rgba(37, 130, 173, 0.3);
  margin-right: 0.4rem;
  border-radius: 0.3rem;

}
.guide .middle{
  vertical-align: top;
  text-align: left;
  width: 6rem;
  height: 1.6rem
}
.guide .middle div{
  display: block;
  height: 0.8rem;
  line-height: 0.8rem;

}
.guide .middle p{
  height: 0.8rem;
  line-height: 0.8rem;
  margin-right: 0.5rem;
}

.guide .middle .ex span{
  color: #ccc;
}

.guide .middle  .way{
  vertical-align: middle;
}
.guide .middle  .way p{
  display: inline-block;
}
.guide .middle  .way .b{
  margin-right: 0.04rem;
}

.guide .middle  .cx{
  background-color: rgba(37, 130, 173, 0.2);
  font-size: 0.36rem;
  height: 0.7rem;
  line-height: 0.7rem;
  color: #fefefe;
  text-align: center;
  padding: 0.01rem 0.4rem;
  border-radius: 0.5rem;

}
.guide .middle .ex span{
  color: #ccc;
  margin-right: 0.3rem;
}
.guide .right{
  vertical-align: top;
  width: 0.8rem;
  height: 1.6rem;
  line-height: 1.6rem;

}
.guide .right img{
  width: 0.5rem;
  height: 0.5rem;
  margin:0.5rem 0rem
}
.guide .right span{
  font-size: 0.6rem;
  color: #ccc;
  width: 0.5rem;
  height: 0.5rem;
  margin:0.5rem 0rem
}
.help{
  display: block;
  text-align: center;
  color: #2b81af;
}

.one_key input, .one_key span, .one_key .van-switch {
  vertical-align:middle;
}
.van-switch{
  margin: 0rem 0.2rem 0rem 0.4rem;
}
.one_key span{
  margin-left: 0.2rem;
}
.guide_warn{
  margin: 0.4rem;
  font-size: 0.4rem;
}
a.one_ming{
  font-size: 0.45rem;
  display: inline-block;
  width: 1.9rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.8rem;
  color: #fefefe;
  margin: 0.2rem 0rem 0.3rem 0.4rem;
  padding-top: 0.06rem;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}
.mings_div{
  text-align: left;
}

#jingxuan .names{
  margin: 0.1rem 0rem 0.3rem 0.6rem;
  display: block;
}
#jingxuan .names span{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
}
#jingxuan .names a{
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.5rem;
  color: #fefefe;
  margin-right: 0.30rem;
  line-height: 0.9rem;
  width: 1.8rem;
}

#jingxuan .more{
  color: #81D3F8;
  background-color:rgba(129, 211, 248, 0.1);
  width: 1.8rem;
  height: 0.9rem;
  line-height: 0.9rem;
}
.shiju{
  margin:0.4rem 0rem;
  padding: 0.5rem 0.5rem;
  text-align: left;
  background-color: #fefefe;
}
.shiju div{
  display: inline-block;
}
.shiju .left{
  width: 8rem;
  vertical-align: middle;
}

.shiju .right{
  width: 0.45rem;
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: middle;
  color: lightgray;
  margin-left: 0.4rem;
}
.xing{
  display: inline-block;
  background-color: rgba(37, 130, 173, 0.3);
  height: 0.5rem;
  line-height: 0.5rem;
  margin: 0 0.08rem;
  border-radius: 0.05rem;
  font-size: 0.32rem;
  padding: 0.1rem 0.16rem;
  color: #fefefe;
}
.shiju_info{
  text-align: center;
  margin: 0.5rem 0 0.3rem 0;
}
.shiju_info .key_list{
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  max-width: 4rem;
  vertical-align: middle;
}
</style>
