<template>
  <van-skeleton v-if="loading" :row="3" />
  <div v-else class="page">
    <div id="zi_head">
        <div class="in_line">
          <p> <span class="key">拼音:</span> <span class="value">{{info.pinyin}}</span></p>
          <p> <span class="key">笔画:</span> <span class="value">{{info.zi_info.bihua}}</span></p>
          <p> <span class="key">五行:</span> <span class="value">{{info.zi_info.wuxing}}</span></p>
        </div>

        <p> <span class="key">同音同调:</span> <span v-for="zi in info.same_diao_list" :key="zi" class="zi_tag" >{{zi}}</span></p>
        <p> <span class="key">同音节字:</span> <span v-for="zi in info.same_yin_list" :key="zi"  class="zi_tag" >{{zi}}</span></p>
      </div>

      <div class="mod3">
        <p class="title">字含义</p>
        <div v-for="(yin, index) in info.zi_info.mean.detail" :key="index" >
          <div v-if="yin.pinyin" class="mean_yin tag" >{{yin.pinyin}}</div>

          <p v-for="(mean,j) in yin.mean" :key="j" class="one_mean">
            <span class="desc2"> {{ mean.desc }}</span>
            <span class="ex2"> {{ mean.ex }} </span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'DetailXing',
  props: {
    info: Map
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
